import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `e-Governance Design Agency`,
    text: "e-Governance Solutions and Services",
    content: "We are a leading e-governance Design Agency offering innovative and user-friendly e-governance solutions to increase public engagement and trust. Our team provides e-governance services for web and mobile apps, improving efficiency, transparency, and accessibility in the public sector.",
    list: [
      'User-centric Design Approach',
      'Innovative Digital Solutions',
      'Comprehensive e-Governance Services'
    ]
  };
  const ourData = {
    title: `Clients Review On Our <span class='h1-span'>e-Governance Design</span>`,
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Driving Modernization With <span class='h1-span'>Our e-Governance Solutions</span>",
    content: [
      {
        title: "10+ Team of Experts",
        text: "Our e-Governance Design Agency has a team of 10+ professionals with expertise spanning digital transformation, user experience, and more, delivering tailored solutions to the unique needs of public sector clients",
      },
      {
        title: "78% Reduced Processing Time",
        text: "We optimise workflows with our e-governance solutions, and have been able to reduce the administrative processing time by 75%, leading to faster service delivery and enhanced efficiency.",
      },
      {
        title: "85% Improved Decision Making",
        text: "We leverage our expertise to transform raw data into actionable insights, enabling 85% improvement in the quality and effectiveness of the decision-making processes of the government organizations.",
      },
      {
        title: "45% Cost Savings",
        text: "Our scalable e-governance design services have helped public sectors achieve substantial 45% cost savings, enabling them to redirect resources towards driving further digital transformation initiatives.",
      },
    ],
  };
  const commonContent1 = {
    title: "Maximizing Impact With Our e-Governance Services",
    content: [
      {
        title: "User-Centric Approach",
        text: "We prioritize a user-centric approach, understand the needs and pain points of government agencies and citizens to deliver seamless experience and maximize user satisfaction.",
      },
      {
        title: "Streamlined Operations",
        text: "Our e-governance services help government organizations streamline their operations, enhancing efficiency and redirecting resources toward more strategic initiatives.",
      },
      {
        title: "Customized Digital Solutions",
        text: "We work closely with the government agencies to develop customized digital solutions, addressing their unique requirements, organizational structure, and more.",
      },
      {
        title: "User-Friendly Interfaces",
        text: "We create intuitive and accessible user interfaces to ensure that e-government portals and mobile applications are easy to navigate, visually appealing, and inclusive.",
      },
      {
        title: "Real-Time Reporting",
        text: "Our e-governance solutions are equipped with robust reporting and analytics capabilities, providing government agencies with real-time insights into service utilization.",
      },
      {
        title: "Future-Proof Designs",
        text: "Our e-governance design agency use latest technologies and scalable architectures to future-proof digital government services and help client adapt to evolving needs.",
      },
    ],
  };
  const Questions = [
    "What services does your e-governance design agency provide?",
    "How does your agency differ from traditional IT service providers?",
    "What are the benefits of partnering with your e-Governance Design Agency?",
    "Can you share some case studies of your e-governance projects?"
  ];
  const faqData = [
    {
        para: [
            `Our e-governance design agency offers a comprehensive suite of digital transformation services for the public sector, including web development, mobile app design, data visualization, and more. We specialize in creating innovative e-government solutions that streamline operations and enhance citizen experiences.`
        ]
    },
    {
      para: [
        `Unlike traditional IT vendors, our e-Governance Design Agency takes a user-centric, design-led approach to digital government transformation. We deeply understand the unique challenges and requirements of the public sector, and leverage cutting-edge technologies to create tailored solutions that address the specific needs of government agencies and citizens.`
      ],
    },
    {
      para: [
        `By collaborating with our e-Governance Design Agency, organizations can expect to see significant improvements in operational efficiency, data-driven decision-making, cost savings, and citizen engagement. Our solutions help government organizations streamline workflows, automate processes, and deliver more accessible and responsive digital services.`
      ]
    },
    {
      para: [
        `We have a strong portfolio of successful e-governance projects that have delivered tangible benefits to our government clients. <a title="Contact us" target="_blank" href="/contact-us/">Contact us</a> and we would be happy to share detailed case studies showcasing how our tailored digital solutions have streamlined operations, improved citizen experiences, and drive measurable improvements.`
      ],
    }
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What services does your e-governance design agency provide?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our e-governance design agency offers a comprehensive suite of digital transformation services for the public sector, including web development, mobile app design, data visualization, and more. We specialize in creating innovative e-government solutions that streamline operations and enhance citizen experiences."
              }
            },{
              "@type": "Question",
              "name": "How does your agency differ from traditional IT service providers?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Unlike traditional IT vendors, our e-Governance Design Agency takes a user-centric, design-led approach to digital government transformation. We deeply understand the unique challenges and requirements of the public sector, and leverage cutting-edge technologies to create tailored solutions that address the specific needs of government agencies and citizens."
              }
            },{
              "@type": "Question",
              "name": "What are the benefits of partnering with your e-Governance Design Agency?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "By collaborating with our e-Governance Design Agency, organizations can expect to see significant improvements in operational efficiency, data-driven decision-making, cost savings, and citizen engagement. Our solutions help government organizations streamline workflows, automate processes, and deliver more accessible and responsive digital services."
              }
            },{
              "@type": "Question",
              "name": "Can you share some case studies of your e-governance projects?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We have a strong portfolio of successful e-governance projects that have delivered tangible benefits to our government clients. Contact us and we would be happy to share detailed case studies showcasing how our tailored digital solutions have streamlined operations, improved citizen experiences, and drive measurable improvements."
              }
            }]
          }                                                              
        `}
      </script>
    </Helmet>
    <Layout hire="e-Governance Design Agency" hotjarHire={true} footerText="Maximize your conversions with Us">
      <div>
        <HireBanner data={banner} hire pageName="Hire Conversion Rate Optimisation" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq grey section Questions={Questions} faqData={faqData} des={''} />
      </div>
    </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="e-Governance Design Agency | e-Governance Solutions"
    description="A leading e-governance design agency offering innovative e-governance solutions for seamless digital transformation and efficient public sector management."
  />
);
